import BackgroundImage from "../../resources/24HourRunBackground.png";
import {Row} from "react-bootstrap";

function PageHeading (props: {children: any}) {
  return (
    <Row style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundAttachment: "fixed", backgroundPosition:"center", margin: "0px"}} fluid>
      <h1 className="fs-1 text-center p-5 my-3">
        <span className="py-4 px-4" style={{borderRadius: "25px", backgroundColor: "#FFA500", border: "5px solid black", fontFamily:"Versa Versa"}}>{props.children}</span>
      </h1>
    </Row>
  ); 
 }
 
 export default PageHeading;