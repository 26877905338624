import {Col, Container, Row} from "react-bootstrap";
import PageHeading from "../utils/PageHeading";

function ContactPage() {

    return (
        <Container fluid style={{fontFamily: "sans-serif"}}>
            <Row>
                <PageHeading>Contact Us</PageHeading>
            </Row>
            <Row>
                <Col>
                    <Row className="my-2">
                        <Col style={{display: "flex"}}>
                            <iframe
                                title="contact-form"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSeYls8YWkVsaeC7nzr89LF4-f5lYBx7UrBW5hQWx4IAQXor1w/viewform?embedded=true"
                                style={{display: "flex", height: "925px", width: "100%"}}
                            >Loading...
                            </iframe>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactPage;