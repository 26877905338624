import {Col, Container, Row, Image, Ratio} from "react-bootstrap";
import Timeline from "./Timeline";
import RunWildLogo from "../../resources/RunWildLogo.jpg";
import HostClubsLogos from "../../resources/HostClubs.jpeg";
import MayorsCharityImage from "../../resources/MayorsCharityLogo.jpeg";
import PageHeading from "../utils/PageHeading";

function AboutPage() {
    return (
        <Container fluid style={{fontFamily: "sans-serif"}}>
            <Row>
                <PageHeading>About us</PageHeading>
            </Row>
            <Row className="justify-content-center mt-5 mb-3">
                <Col className="mb-3 px-4 text-center fs-5" md={9} lg={8} xl={7}>
                    <Row className="mb-5">
                        <h2 className="fw-bold">How did it start?</h2>
                        <p>The Manchester 24 Hour Run Against Homelessness was set up in 2019 by members of <b>Run Wild MCR</b>, the social running society at the University of Manchester. During our time as students, we became proud to be a part of this vibrant and inclusive city, but with homelessness rates in Greater Manchester among the highest in the country, thousands of people remain literally locked out. The Manchester 24 Hour Run Against Homelessness was born out of our desire to bring people together from across the city and support the work of local organisations helping people off the streets and back into the community.</p>
                        <Image fluid src={RunWildLogo} alt="Run Wild" style={{maxHeight: "calc(100vh - 96px)", objectFit: "scale-down"}} />
                    </Row>
                    <Row><div className="divider div-transparent div-dot mb-5 mt-1"></div></Row>
                    <Row>
                        <h2 className="fw-bold">How is it going?</h2>
                        <p>{"Although our main event team has now left the university, many of us have made Manchester our permanent home. Run Wild remains heavily involved, providing an incredible team of run leaders to support our ever-increasing turnout of runners from across the city."}</p>
                        <p>From 2024 onwards, we also have some new host clubs - <b>Manchester Road Runners</b>, and <b>Manchester Run Club</b>. They will be also be providing run leaders and huge amounts of help and support for the future.</p>
                        <p>{"In the 5 editions of the event so far, from 2019 to 2023, we have raised a cumulative total of £52,807.99, funding 1760 beds. But this is still not enough - homelessness rates remain high and there is still vital work to be done."}</p>
                        <Image className="mt-4 mb-4" fluid src={HostClubsLogos} alt="Host Running Clubs" style={{maxHeight: "calc(100vh - 96px)", objectFit: "scale-down"}} />
                    </Row>
                </Col>
                <Timeline/>
                <Col className="mb-3 mt-5 px-4 text-center fs-5" md={9} lg={8} xl={7}>
                    <Row className="mb-5">
                        <h2 className="fw-bold">Who do we raise money for?</h2>
                        <p>We have been partnered with the <a className="fw-bold" href="https://gmmayorscharity.org.uk/">Greater Manchester Mayor's Charity</a> since 2019, raising money for their "A Bed Every Night" scheme. During this time, homelessness rates in Greater Manchester have halved, but there are still thousands more people sleeping on the streets or living in temporary accommodation.</p>
                        <Image fluid className="mt-3" src={MayorsCharityImage} alt="Mayor's Charity Logo" style={{maxHeight:  "calc(100vh - 96px)"}} />
                    </Row>
                    <Row><div className="divider div-transparent div-dot mb-5 mt-4"></div></Row>
                    <Row className="mb-5">
                        <h2 className="fw-bold">How does our fundraising help?</h2>
                        <p>The Greater Manchester Mayor's Charity has supported over 4000 individuals and 165 organisations since 2019. They have funded 70,000 nights of accommodation through the "A Bed Every Night" scheme, and awarded over £3m in grants to local causes.</p>
                        <Ratio aspectRatio={"16x9"}>
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/OM2tBHLgwJc"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </Ratio>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutPage;