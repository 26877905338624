import {Row, Col, Card, Button} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";

function ShakeoutSubPage () {
  return(
    <Row className="mb-3 py-4 mx-0 justify-content-center">
        <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
            <Row>
                <h2 className="fs-2 fw-bold">5K Shakeout Run</h2>
            </Row>
            <Row className="fw-bold justify-content-center">
                <Col sm={8} md={6} className="mt-3 justify-content-center">
                    <ElevatedCard colour="#FFA500" elevation={4}>
                        <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"6:45pm, Wednesday 18th October 2023"}</Card.Body>
                    </ElevatedCard>
                </Col>
                <Col sm={8} md={6} className="mt-3">
                    <ElevatedCard colour="#FFCC00" elevation={4}>
                        <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"53two, Arch 19 Watson St, Manchester M3 4LP"}</Card.Body>
                    </ElevatedCard>
                </Col>
            </Row>
            <Row className="justify-content-center fs-5">
                <p className="mt-4">A new event for 2023, our 5K shakeout run will be held 4 weeks before the main event, and is open to all abilities with run leaders to guide you. The shakeout run will be a great opportunity to find out more about our main event in November, and help kick-start our fundraising campaign.</p>
                <p>We will meet at 53Two from 6:45pm onwards. There, we will split into several different pace groups that cover all abilities. We will run one lap of our 5km route, returning to 53Two by around 8pm. The bar will be open for food and drinks afterwards, and our event organisers and run leaders will be on hand to meet you and answer any questions you may have about the main event.</p>
                <Col><Button href="https://docs.google.com/forms/d/e/1FAIpQLSeYMOsEKjG7mic6KfMgNc-Pg2NwQg_ydrZgQoV9jfecF2BtQw/viewform">Sign up to email reminders</Button></Col>
            </Row>            
        </Col>
    </Row>
  );
}

export default ShakeoutSubPage;