import {Button, Container, Row} from "react-bootstrap";
import {useState} from "react";

const LapCounterWidget = () => {
    const [laps, setLaps] = useState(1);

    return (
        <Container>
            <Row xs="auto" style={{padding: 30}}>
                <Button onClick={() => setLaps(laps + 1)} style={{width: 50}}>+</Button>
            </Row>
            <Row>
                <h1 style={{color: "white", fontSize: "45px"}}>Lap Number <b style={{color: "orange"}}>{laps}</b></h1>
            </Row>
            <Row xs="auto" style={{padding: 30}}>
                <Button onClick={() => setLaps(laps - 1)} style={{width: 50}}>-</Button>
            </Row>

        </Container>
    );
}

export default LapCounterWidget;