import {Row, Col, Card} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";

function ParkrunSubPage () {
  return(
    <Row className="mb-3 py-4 mx-0 justify-content-center">
        <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
            <Row>
                <h2 className="fs-2 fw-bold">Parkrun Meetup</h2>
            </Row>
            <Row className="fw-bold justify-content-center">
                <Col sm={8} md={6} className="mt-3 justify-content-center">
                    <ElevatedCard colour="#FFA500" elevation={4}>
                        <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"9am, Saturday 16th September 2023"}</Card.Body>
                    </ElevatedCard>
                </Col>
                <Col sm={8} md={6} className="mt-3">
                    <ElevatedCard colour="#FFCC00" elevation={4}>
                        <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"Stretford Parkrun"}</Card.Body>
                    </ElevatedCard>
                </Col>
            </Row>
            <Row className="justify-content-center fs-5">
                <p className="mt-4">New this year, our parkrun meetup event marks the beginning of our 2023 fundraising campaign. Our event team will be attending Stretford Parkrun and will be available to answer any questions you may have about our events.</p>
            </Row>            
        </Col>
    </Row>
  );
}

export default ParkrunSubPage;