import {Card, Col, Container, Row} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";
import PageHeading from "../utils/PageHeading";
import {Link, Outlet, useLocation} from "react-router-dom";
import MainEventSubPage from "./MainEventSubPage";

function GetInvolvedPage() {
    const RoutePathMatch = (path: string): string => {
        if (useLocation().pathname !== path) {
            return "#ffff00";
        }
        else {return "#ffffff"}
    };
    
    return (
        <Container fluid style={{fontFamily: "sans-serif", flexGrow: "1", flexShrink: "0", flexBasis: "auto"}}>
            <Row>
                <PageHeading>Get Involved</PageHeading>
            </Row>
            <Row className="mt-4">
                <MainEventSubPage />
            </Row>
            {/*<Row className="my-5 mx-0">*/}
            {/*    <Col>*/}
            {/*        <Row><h2 className="text-center fw-bold fs-2 mb-4">Our Events - Click to find out more:</h2></Row>*/}
            {/*        <Row >*/}
            {/*            <Col sm className="mb-4">*/}
            {/*                <Link to="./parkrun-meetup" style={{textDecoration:"none"}}>*/}
            {/*                    <ElevatedCard elevation={4} colour={RoutePathMatch("/get-involved/parkrun-meetup")}>*/}
            {/*                        <Card.Body>*/}
            {/*                            <Card.Title className="text-center">Parkrun Meetup</Card.Title>*/}
            {/*                            <Card.Subtitle className="text-center"><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Saturday 16th September 2023"}</Card.Subtitle>*/}
            {/*                        </Card.Body>*/}
            {/*                    </ElevatedCard>*/}
            {/*                </Link>*/}
            {/*            </Col>*/}
            {/*            <Col sm className="mb-4">*/}
            {/*                <Link to="./5k-shakeout" style={{textDecoration:"none"}}>*/}
            {/*                    <ElevatedCard elevation={4} colour={RoutePathMatch("/get-involved/5k-shakeout")}>*/}
            {/*                        <Card.Body>*/}
            {/*                            <Card.Title className="text-center" >5K Shakeout Run</Card.Title>*/}
            {/*                            <Card.Subtitle className="text-center"><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Wednesday 18th October 2023"}</Card.Subtitle>*/}
            {/*                        </Card.Body>*/}
            {/*                    </ElevatedCard>*/}
            {/*                </Link>*/}
            {/*            </Col>*/}
            {/*            <Col sm className="mb-4">*/}
            {/*                <Link to="./main-event" style={{textDecoration:"none"}}>*/}
            {/*                    <ElevatedCard elevation={4} colour={RoutePathMatch("/get-involved/main-event")}>*/}
            {/*                        <Card.Body>*/}
            {/*                            <Card.Title className="text-center">The Main Event</Card.Title>*/}
            {/*                            <Card.Subtitle className="text-center"><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Wednesday 15th - Thursday 16th November 2023"}</Card.Subtitle>*/}
            {/*                        </Card.Body>*/}
            {/*                    </ElevatedCard>*/}
            {/*                </Link>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Col>            */}
            {/*</Row>*/}
            {/*<Outlet/>*/}
        </Container>
    );
}

export default GetInvolvedPage;