import {Container, Row} from "react-bootstrap";
import {useEffect} from "react";
import PageHeading from "../utils/PageHeading";

/*function importAll(r: __WebpackModuleApi.RequireContext) {
    return r.keys().map((item) => r(item));
}*/

function GalleryPage() {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://embedr.flickr.com/assets/client-code.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <Container fluid>
            <Row>
                <PageHeading>Gallery</PageHeading>
            </Row>

            <Row>
                <Container className="text-center fw-bold fs-1 my-3">2023</Container>
            </Row>
            <Row><div className="divider div-transparent mb-3 d-xs-block d-lg-none"></div></Row>
            <Row className="justify-content-center align-items-center">
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720312971762" title="The Manchester 24 Hour Run Against Homelessness 2023 Lap Photos">
                        <img
                            src="https://live.staticflickr.com/65535/53360539896_fabde05dc8_z.jpg" width="640" height="480"
                            alt="The Manchester 24 Hour Run Against Homelessness 2023 Lap Photos"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720312971762" title="The Manchester 24 Hour Run Against Homelessness 2023 Lap Photos">
                        <img
                            src="https://live.staticflickr.com/65535/53360539896_fabde05dc8_n.jpg" width="320" height="240"
                            alt="The Manchester 24 Hour Run Against Homelessness 2023 Lap Photos"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720312833581" title="The Manchester 24 Hour Run Against Homelessness 2023 Event Photos">
                        <img
                            src="https://live.staticflickr.com/65535/53346607739_a5f2186bbf_m.jpg" width="640" height="480"
                            alt="The Manchester 24 Hour Run Against Homelessness 2023 Event Photos"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720312833581" title="The Manchester 24 Hour Run Against Homelessness 2023 Event Photos">
                        <img
                            src="https://live.staticflickr.com/65535/53346607739_a5f2186bbf_m.jpg" width="320" height="240"
                            alt="The Manchester 24 Hour Run Against Homelessness 2023 Event Photos"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
            </Row>

            <Row>
                <Container className="text-center fw-bold fs-1 my-3">2022</Container>
            </Row>
            <Row><div className="divider div-transparent mb-3 d-xs-block d-lg-none"></div></Row>
            <Row className="justify-content-center align-items-center">
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720304050392" title="The Manchester 24 Hour Run Against Homelessness 2022">
                        <img src="https://live.staticflickr.com/65535/52484953851_0b1d7c5f45_z.jpg" width="640" height="480" alt="The Manchester 24 Hour Run Against Homelessness 2022"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720304050392" title="The Manchester 24 Hour Run Against Homelessness 2022">
                        <img src="https://live.staticflickr.com/65535/52484953851_0b1d7c5f45_n.jpg" width="320" height="240" alt="The Manchester 24 Hour Run Against Homelessness 2022"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720304056124" title="The Manchester 24 Hour Run Against Homelessness 2022">
                        <img src="https://live.staticflickr.com/65535/52484953851_0b1d7c5f45_z.jpg" width="640" height="480" alt="The Manchester 24 Hour Run Against Homelessness 2022"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720304056124" title="The Manchester 24 Hour Run Against Homelessness 2022">
                        <img src="https://live.staticflickr.com/65535/52529971836_50cc062704_z.jpg" width="320" height="240" alt="The Manchester 24 Hour Run Against Homelessness 2022"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center fw-bold fs-1 my-3">2021</Container>
            </Row>
            <Row><div className="divider div-transparent mb-3 d-xs-block d-lg-none"></div></Row>
            <Row className="justify-content-center align-items-center">
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720303509389" title="The Manchester 24 Hour Run Against Homelessness 2021">
                        <img src="https://live.staticflickr.com/65535/52484953851_0b1d7c5f45_z.jpg" width="640" height="480" alt="The Manchester 24 Hour Run Against Homelessness 2021"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true" href="https://www.flickr.com/photos/196883439@N07/albums/72177720303509389" title="The Manchester 24 Hour Run Against Homelessness 2021">
                        <img src="https://live.staticflickr.com/65535/52484953851_0b1d7c5f45_n.jpg" width="320" height="240" alt="The Manchester 24 Hour Run Against Homelessness 2021"
                            style={{objectFit: "contain"}}
                        />
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center fw-bold fs-1 mb-3 mt-5">2020</Container>
            </Row>
            <Row><div className="divider div-transparent mb-3 d-xs-block d-lg-none"></div></Row>
            <Row>
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true"
                       href="https://www.flickr.com/photos/196883439@N07/albums/72177720303509509"
                       title="The Manchester 24 Hour Run Against Homelessness 2020"><img
                        src="https://live.staticflickr.com/65535/52485425150_3bb4d3994d_z.jpg" width="640" height="480"
                        alt="The Manchester 24 Hour Run Against Homelessness 2020"/>
                    </a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true"
                       href="https://www.flickr.com/photos/196883439@N07/albums/72177720303509509"
                       title="The Manchester 24 Hour Run Against Homelessness 2020"><img
                        src="https://live.staticflickr.com/65535/52485425150_3bb4d3994d_n.jpg" width="320" height="240"
                        alt="The Manchester 24 Hour Run Against Homelessness 2020"/>
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center fw-bold fs-1 mb-3 mt-5">2019</Container>
            </Row>
            <Row><div className="divider div-transparent mb-3 d-xs-block d-lg-none"></div></Row>
            <Row className="mb-5">
                <Container className="d-none d-md-flex" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true"
                       href="https://www.flickr.com/photos/196883439@N07/albums/72177720303518958"
                       title="The Manchester 24 Hour Run Against Homelessness 2019"><img
                        src="https://live.staticflickr.com/65535/52484480862_43437edb00_z.jpg" width="640" height="480"
                        alt="The Manchester 24 Hour Run Against Homelessness 2019"/></a>
                </Container>
                <Container className="d-md-none" style={{width: "max-content", maxWidth: "100vw"}}>
                    <a data-flickr-embed="true"
                       href="https://www.flickr.com/photos/196883439@N07/albums/72177720303518958"
                       title="The Manchester 24 Hour Run Against Homelessness 2019"><img
                        src="https://live.staticflickr.com/65535/52484480862_43437edb00_n.jpg" width="320" height="240"
                        alt="The Manchester 24 Hour Run Against Homelessness 2019"/></a>
                </Container>
            </Row>
        </Container>

    );
}

export default GalleryPage;