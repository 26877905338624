import {Container, Row} from "react-bootstrap";
import PageHeading from "../utils/PageHeading";
import FundraisingBar from "../components/fundraising-bar";

function LivePage () {
  return (
    <Container fluid style={{fontFamily: "sans-serif", flexGrow: "1", flexShrink: "0", flexBasis: "auto"}}>
        <Row>
            <PageHeading>Latest</PageHeading>
        </Row>
        <Row style={{height: "250px"}}>
            <FundraisingBar/>
        </Row>
    </Container>
  );
}

export default LivePage;