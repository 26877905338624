import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import {useState, useEffect} from "react";

Chart.register(annotationPlugin);
Chart.register(CategoryScale);
// Chart.register(ChartDataLabels);
Chart.defaults.font.family = "Versa Versa";

function FundraisingBar () {
  const update = () => {
    fetch("https://api.justgiving.com/d4fe6f1f/v1/teamsv3/mcr-24-hour-run-2023",
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(async http => {
          let content = http.json();
          if (http.ok) {
            return content;
          }
          else {
            return content.then(content => {throw new Error(content);})
          }
        }
      ).then(response => {
        console.log("Updating chart");
        setChartData({
          labels: [""],
          datasets: [
            {
              label: "Donations",
              backgroundColor: "#00ff00",
              data: [response.donationSummary.totalAmount],
            },
            {
              label: "Gift Aid",
              backgroundColor: "#7DF9FF",
              data: [response.donationSummary.totalGiftAid],
            }
          ]
        })
      })
  }

  const [chartData, setChartData] = useState({
    labels: [""],
    datasets: [
      {
        label: "Donations",
        backgroundColor: "#00FF00",
        data: [0],
      },
      {
        label: "Gift Aid",
        backgroundColor: "#253c75",
        data: [0],
      }
    ]
  }); 

  const [chartOptions, setChartOptions] = useState({})

  useEffect(() => setChartOptions({
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        font: {
          size: 50,
        },
        formatter: (val: string) => {
          return "£" + val;
        },
        color: "#000000",
      },
      title: {
        display: false,
        text: "Fundraising",
        font: {
          size: 30,
        },
      },
      legend: {
        display: true,
        textAlign: "left",
        align: "end",
        position: "top"
      },
      annotation: {
        annotations: {
          targetLine: {
            type: 'line',
            xMin: 10000,
            xMax: 10000,
            borderColor: 'rgb(158, 11, 133)',
            borderWidth: 5
          },
          targetLabel: {
            type: 'label',
            xValue: 10000,
            content: ["£10,000 Target"],
            font: {
              size: 18
            },
            backgroundColor: 'rgb(158, 11, 133)',
            borderRadius: 10,
            color: '#ffffff',
            position: 'end'
          }
        }
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          // Include a pound sign in the ticks
          callback: function(value:string) {
              return '\u00A3' + value;
          }
        }
      },
      y: {
        stacked: true,
      },
    },
    categoryPercentage: 1.0,
    barPercentage: 1,
  }),[])

  useEffect(() => {
    update();
    const interval = setInterval(() => {
      update();
    }, 60000);
    return () => clearInterval(interval);
  },[])
  

  return (
    <>
      <Bar className="m-4" data={chartData} options={chartOptions} plugins={[ChartDataLabels]}></Bar>
    </>
  )
}

export default FundraisingBar;