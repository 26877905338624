import {Button, Card, Col, Container, Ratio, Row} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";

function PostEventHomePage() {

    return (
        <Container fluid>
            <Row className="landing-screen" style={{alignItems: "center"}}>
                <Container>
                    <Row>
                        <Col>
                            <Container className="fs-1 py-3" style={{
                                color: "black",
                                fontFamily: "Versa Versa",
                                borderRadius: "25px", backgroundColor: "#FFA500", border: "5px solid black"
                            }}>
                                Thank you all for attending!
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container className="fs-2 mt-5 py-2" style={{
                                color: "black",
                                fontFamily: "Versa Versa",
                                borderRadius: "25px", backgroundColor: "#FFCC00",
                            }}>
                                The event is now over, but please continue to donate!
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="mt-5 fs-1 w-40 p-3" as="a" href="https://www.justgiving.com/page/mcr-24-hour-run-2023" variant="primary" style={{padding: "8px 12px", borderRadius: "5px",
                                backgroundColor: "#9e0b85",
                                textDecoration: "none",
                                color: "white",
                                fontFamily: "Versa Versa",
                            }}>
                                Donate
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </Row>

            <Row><div className="divider div-transparent div-dot mb-3 mt-5"></div></Row>

            <Row className="my-3 py-4 justify-content-center">
                <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
                    <Row>
                        <h1 className="fs-2 fw-bold">The Manchester 24 Hour Run Against Homelessness 2023</h1>
                    </Row>
                    <Row className="fw-bold justify-content-center">
                        <Col sm={8} md={6} className="mt-3 justify-content-center">
                            <ElevatedCard colour="#FFA500" elevation={4}>
                                <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Wednesday 15th - Thursday 16th November 2023"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                        <Col sm={8} md={6} className="mt-3">
                            <ElevatedCard colour="#FFCC00" elevation={4}>
                                <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"53two, Arch 19 Watson St, Manchester M3 4LP"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                    </Row>
                    <Row>
                        <p className="fs-5 text-center mt-4">{"The Manchester 24 Hour Run Against Homelessness is a mass participation group relay run that is held over 24 hours. Runners and walkers set off together in waves to complete 5km laps in the city centre of Manchester, all to raise money for the Greater Manchester Mayor's Charity scheme 'A Bed Every Night'."}</p>
                    </Row>
                    <Row xs="auto" style={{justifyContent: "center"}}>
                        <Button className="mt-3 fs-3 px-3 py-2" as="a" href="/get-involved" variant="primary">
                            Find out more
                        </Button>
                    </Row>
                </Col>
            </Row>

            <Row><div className="divider div-transparent div-dot mb-5"></div></Row>
            <Row>
                <Ratio aspectRatio={"16x9"}>
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/OM2tBHLgwJc"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </Ratio>
            </Row>
        </Container>
    );
}

export default PostEventHomePage;