import {Button, Col, Container, Row} from "react-bootstrap";
import PageHeading from "../utils/PageHeading";

export const PressPage = () => {
    return (
        <Container fluid style={{fontFamily: "sans-serif"}}>
            <Row>
                <PageHeading>In the Press</PageHeading>
            </Row>
            <Container>
                <Row className="justify-content-center mt-4">
                    <Col className="px-4 text-center">
                        <Row>
                            <h2 className="fs-3 fw-bold">Click on Headlines to Read More</h2>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md style={{display: "flex"}}>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-3 fs-2 w-75" as="a" href="https://www.bbc.co.uk/news/uk-england-manchester-67438114?fbclid=IwAR2Lh7YKXI6wPK2UytQ7U3HSY-HiZUBEvuq5kjRw05Vg_1zmZQ_0fQr5Gb4" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"Manchester 24-hour relay sheds light on homelessness plight\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-BBC, 2023</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                    <Col md>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-3 fs-2 w-75" as="a" href="https://themanc.com/news/manchester-runners-protest-banner-suella-braverman-charity-run-for-the-homeless/" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"Manchester runners send message to Suella Braverman and the Conservatives with homeless protest banner\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-The Manc, 2023</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col md style={{display: "flex"}}>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-lg-5 mt-3 fs-2 w-75" as="a" href="https://themanc.com/sport/manchester-runners-raise-over-25k-in-24-hours-2023-homeless-charity-run/" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"Manchester runners raise over £25,000 in 24 hours with 2023 homeless charity run\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-The Manc, 2023</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                    <Col md>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-lg-5 mt-3 fs-2 w-75" as="a" href="https://themanc.com/sport/manchester-24-hour-run-against-homelessness-charity-relay-run-2023/" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"Manc runners are uniting once again for the Manchester 24 Hour Run Against Homelessness\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-The Manc, 2023</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md style={{display: "flex"}}>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-lg-5 mt-3 fs-2 w-75" as="a" href="https://themanc.com/news/manchester-24-hour-run-against-homelessness-2022/" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"Hundreds of Manchester runners are gathering for a 24-hour marathon to fight homelessness\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-The Manc, 2022</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                    <Col md style={{display: "flex"}}>
                        <Container style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mt-lg-5 mt-3 fs-2 w-75" as="a" href="https://confidentials.com/manchester/10-manchester-fitness-wellbeing-things-to-do-november" size="lg" style={{ border: "2px solid black" }}>
                                <Container fluid className="px-2" style={{display: "flex", height: "100%", justifyContent: "center", flexDirection: "column"}}>
                                    <Row className="text-start fs-5 fw-light mb-1 fst-italic">
                                        <Col xs>
                                            {"\"First things first: you don't have to run for the full 24 hours.\""}
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "end"}}>
                                        <Col className="text-end fs-4">-Confidentials, 2022</Col>
                                    </Row>
                                </Container>
                            </Button>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}