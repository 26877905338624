import {Container, Row, Col} from "react-bootstrap";

function Footer () {
  return (
    <Container fluid className="bg-secondary p-5 text-center fs-3 f-versa" style={{color: "black"}}>
      <Row>
        <Col md className="my-3">
          <a href="https://www.facebook.com/MCR24HourRun"><i className="fa-brands fa-facebook"></i></a>&nbsp;&nbsp;
          <a href="https://www.instagram.com/mcr24hourrun/"><i className="fa-brands fa-instagram"></i></a>&nbsp;&nbsp;
          <a href="https://twitter.com/mcr24hourrun"><i className="fa-brands fa-x-twitter"></i></a>&nbsp;&nbsp;
          <a href="https://www.youtube.com/watch?v=esvqqsqrjMY"><i className="fa-brands fa-youtube"></i></a>&nbsp;&nbsp;
          <a href="https://www.flickr.com/photos/196883439@N07/albums/with/72177720304050392"><i className="fa-brands fa-flickr"></i></a>&nbsp;&nbsp;
          <a href="mailto:info@mcr24hourrun.co.uk"><i className="fa-regular fa-envelope"></i></a>
        </Col>  
        <Col md className="my-3">
          <i className="fa-solid fa-at"></i> mcr24hourrun
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;